.App {
  text-align: center;
}

.App-header {
  background: linear-gradient(150deg, #051e34, #25658f);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ffffff;
}

.App-logo {
  width: 80vw;
  pointer-events: none;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1));
}
